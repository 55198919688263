
export function scrollToDestination(destination) {
  if (typeof window !== 'undefined') {
    const supportsNativeSmoothScroll =
      'scrollBehavior' in document.documentElement.style;

    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce), (update: none), (update: slow)').matches;

    if (supportsNativeSmoothScroll && !prefersReducedMotion) {
      window.scrollTo({
        top: destination,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo(0, destination);
    }
  }
}
