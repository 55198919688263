import React from 'react';
import PropTypes from 'prop-types';
import Styles from './square.module.css';

const AspectRatioBox = ({ aspectRatio, children, className }) => (
  <div
    className={`${Styles.square} ${className}`}
    style={{ paddingBottom: `${100 / aspectRatio}%` }}
  >
    <div className={Styles.squareInner}>{children}</div>
  </div>
);

AspectRatioBox.defaultProps = {
  aspectRatio: 1,
  className: '',
};

AspectRatioBox.propTypes = {
  aspectRatio: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default AspectRatioBox;
