class SoundManager {
  constructor() {
    if (typeof window !== 'undefined') {
      window.SoundManager = this;
      this.Sono = require('sono/dist/sono.js');
    }
    this.sounds = {};
  }
  unmute() {
    if (typeof window == 'undefined') {
      return;
    }
    this.isMuted = false;
    this.Sono.unMute();
  }
  mute() {
    if (typeof window == 'undefined') {
      return;
    }
    this.isMuted = true;
    this.Sono.mute();
  }
  addSound(url, id, options) {
    if (this.disabled || this.sounds[id]) return;

    if (typeof window == 'undefined') {
      return;
    }

    options = options || {};

    const src = [`/audio/ogg/${url}.ogg`, `/audio/mp3/${url}.mp3`];
    const volume = options.volume !== undefined ? options.volume : 1;
    const sound = this.Sono.createSound({
      src,
      autoplay: options.autoplay || false,
      loop: options.loop || false,
      volume,
    });

    if (options.autoplay) {
      sound.play();
    }

    sound.realVolume = volume;
    this.sounds[id] = sound;
  }

  play(id) {
    if (this.sounds[id]) {
      this.sounds[id].play();
    }
  }

  playUnique(id) {
    for (let i in this.sounds) {
      this.sounds[i].stop();
    }

    if (this.sounds[id]) {
      this.sounds[id].play();
    }
  }

  pause(id) {
    if (this.sounds[id]) {
      this.sounds[id].pause();
    }
  }

  stop(id) {
    if (this.sounds[id]) {
      this.sounds[id].stop();
    }
  }
}

export default new SoundManager();
