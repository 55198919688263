import React, { useEffect, useRef, useState } from 'react';

import Styles from './fx-player.module.css';

const FxPlayerButton = props => {
  const btnRef = useRef(null);
  const [height, setHeight] = useState(0);
  let btn;

  useEffect(() => {
    handleHeight();
  });

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('keydown', handleResize);
  });

  function handleHeight() {
    if (btnRef.current) setHeight(btnRef.current.clientWidth);
  }

  function handleResize() {
    if (btnRef.current) setHeight(btnRef.current.clientWidth);
  }

  try {
    btn = require(`images/fx/btn0${props.index}.svg`);
  } catch (e) {}

  return (
    <button
      ref={btnRef}
      style={{ height: height + 'px' }}
      className={`${props.className} ${Styles.fxButton} ${
        Styles[`${props.theme}`]
      }`}
      onClick={props.onClick}
      onMouseUp={e => {
        e.target.blur();
      }}
    >
      <img src={btn} alt="" />
    </button>
  );
};

export default FxPlayerButton;
