import React from 'react';

const BackArrow = props => (
  <svg {...props} viewBox="0 0 14 11" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M7.264 0.604448L3.792 4.58845L13.664 4.58845V6.47645H3.792L7.264 10.4604H4.384L9.53674e-07 5.53245L4.384 0.604448H7.264Z"
    />
  </svg>
);

export default BackArrow;
