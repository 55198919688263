import CtaArrow from 'components/svgs/ctaarrow';
import PropTypes from 'prop-types';
import React from 'react';
import Styles from './cta.module.css';

const Cta = ({ href, children, className, onClick }) => {
  return (
    <a
      className={`${className} ${Styles.cta}`}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      <span className={Styles.children}>{children}</span>
      <CtaArrow className={Styles.icon} width="20" height="20" />
    </a>
  );
};

Cta.defaultProps = {};

Cta.propTypes = {
  href: PropTypes.string.isRequired,
};

export default Cta;
