import React from 'react';

const PullUp = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 522 111">
    <path
      fill="#FD483A"
      d="M513.3,59.4c4-1.3,8-2.5,8-4.2c0-1.7-4-3-8-4.2c-3.7-1.2-7.5-2.4-7.7-3.9c-0.3-1.5,2.8-4.1,5.8-6.7
	c3.1-2.6,6.2-5.3,5.6-6.7c-0.6-1.4-4.7-1-8.8-0.6c-3.9,0.4-7.8,0.8-8.6-0.4c-0.8-1.2,1-4.7,2.9-8.2c1.9-3.6,3.9-7.2,2.8-8.3
	c-1.1-1.1-4.7,0.8-8.3,2.8c-3.5,1.9-7,3.7-8.2,2.9c-1.2-0.8-0.8-4.7-0.4-8.6c0.4-4.1,0.9-8.2-0.6-8.8c-1.5-0.6-4.1,2.5-6.7,5.6
	c-2.6,3-5.1,6.1-6.7,5.8c-1.5-0.3-2.7-4-3.9-7.8c-1.3-4-2.5-8-4.2-8c-1.7,0-3,4-4.2,8c-1.2,3.7-2.4,7.5-3.9,7.7
	c-1.5,0.3-4.1-2.8-6.7-5.8c-2.6-3.1-5.3-6.2-6.7-5.6c-1.4,0.6-1,4.7-0.6,8.8c0.4,3.9,0.8,7.8-0.4,8.6c-1.2,0.8-4.7-1-8.2-2.9
	c-3.6-1.9-7.2-3.9-8.3-2.8c-1.1,1.1,0.8,4.7,2.8,8.3c1.9,3.5,3.7,7,2.9,8.2c-0.8,1.2-4.7,0.8-8.6,0.4c-4.1-0.4-8.2-0.9-8.8,0.6
	c-0.6,1.4,2.5,4.1,5.6,6.7c3,2.6,6.1,5.1,5.8,6.7c-0.3,1.5-4,2.7-7.7,3.9c-4,1.3-8,2.5-8,4.2c0,1.7,4,3,8,4.3
	c3.7,1.2,7.5,2.4,7.7,3.9c0.3,1.5-2.8,4.1-5.8,6.7c-3.1,2.6-6.2,5.3-5.6,6.7c0.6,1.4,4.7,1,8.8,0.6c3.9-0.4,7.8-0.8,8.6,0.4
	c0.8,1.2-1,4.7-2.9,8.2c-1.9,3.6-3.9,7.2-2.8,8.3c1.1,1.1,4.7-0.8,8.3-2.8c3.5-1.9,6.9-3.7,8.2-2.9c1.2,0.8,0.8,4.7,0.4,8.6
	c-0.4,4.1-0.9,8.2,0.6,8.8c1.4,0.6,4.1-2.5,6.7-5.6c2.6-3,5.1-6.1,6.7-5.8c1.5,0.3,2.7,4,3.9,7.7c1.3,4,2.5,8,4.2,8c1.7,0,3-4,4.2-8
	c1.2-3.7,2.4-7.5,3.9-7.7c1.5-0.3,4.1,2.8,6.7,5.8c2.6,3.1,5.3,6.2,6.7,5.6c1.4-0.6,1-4.7,0.6-8.8c-0.4-3.9-0.8-7.8,0.4-8.6
	c1.2-0.8,4.7,1,8.2,2.9c3.6,1.9,7.2,3.9,8.3,2.8c1.1-1.1-0.8-4.7-2.8-8.4c-1.9-3.5-3.7-6.9-2.9-8.2c0.8-1.2,4.7-0.8,8.6-0.4
	c4.1,0.4,8.2,0.9,8.8-0.6c0.6-1.4-2.5-4.1-5.6-6.7c-3-2.6-6.1-5.1-5.8-6.7C505.9,61.8,509.6,60.6,513.3,59.4z M458,30.3l1.5-1.5
	c0.3-0.3,0.6-0.4,1-0.4h4.5c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.5,0.3l1.6,1.6c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.5
	v8.4h3.5c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.5,0.3l1.8,1.8c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.5v0.2h3.2
	c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.5,0.3l1.7,1.7c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.5c0,0.2,0,0.4-0.1,0.5
	l2.6,1.7c0.3,0.2,0.5,0.4,0.5,0.7l1.5,4.5l0.1,0.5v14.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.5l-0.2,0.2l-1.7,5.7
	c-0.1,0.3-0.3,0.6-0.6,0.8L481,81c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.8,0.3h-20.1c-0.2,0-0.4,0-0.5-0.1
	c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.5v-3.9h-0.7c-0.4,0-0.7-0.1-1-0.4
	c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.5v-3h-0.8c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.5-0.3
	c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.5v-3h-0.9c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.5-0.3
	c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.5v-5h-0.9c-0.4,0-0.7-0.1-1-0.4c-0.1-0.1-0.2-0.3-0.3-0.5
	c-0.1-0.2-0.1-0.4-0.1-0.5v-0.2h-1.1c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.5
	c-0.1-0.2-0.1-0.4-0.1-0.5v-6.7c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.1,0.5-0.1h7.2
	c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.5v1.1h0.9V31.3c0-0.2,0-0.4,0.1-0.5
	C457.8,30.6,457.9,30.4,458,30.3z M465.3,32l-0.8-0.8h-3.3l-0.7,0.7v29.3c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.5
	c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.5,0.1c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.5
	c-0.1-0.2-0.1-0.4-0.1-0.5v-5.5h-2.3c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.5
	c-0.1-0.2-0.1-0.4-0.1-0.5v-1.1h-4.5v3.9h1c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.5
	c0.1,0.2,0.1,0.4,0.1,0.5v0.2h0.9c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.5s0.1,0.4,0.1,0.5v5h0.9
	c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.5v3h0.9c0.2,0,0.4,0,0.5,0.1
	c0.2,0.1,0.3,0.2,0.5,0.3c0.3,0.3,0.4,0.6,0.4,1v3h0.7c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.4
	c0.1,0.2,0.1,0.3,0.1,0.5v3.9h17.8l1.6-4.4c0.1-0.3,0.3-0.5,0.5-0.7l1.9-5.8c0.1-0.2,0.2-0.4,0.3-0.5V54.3l-1.3-3.8l-1-0.6v5.2
	c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1v-7.8c0-0.3,0.1-0.5,0.2-0.7l-0.6-0.6
	h-3.1v5.3c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1v-6.6c0-0.2,0-0.4,0.1-0.5
	c0.1-0.2,0.2-0.3,0.3-0.5l-1-1h-3v10c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.3-0.5,0.3
	c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.6V32z"
    />
    <path
      fill="currentColor"
      d="M17.1,20h29.3c13,0,21,5.5,21,15.8C67.4,48.4,54.6,58,37,58H26.4l-6.7,26.7l0.6,0.9h10.8l-0.7,3.1H0l0.7-3.1H9
	l1.2-0.9L25.3,24l-0.6-0.9h-8.2L17.1,20z M34.6,25l-7.1,28.1h9.5c10.5,0,19.3-5.2,19.3-17.1c0-7.8-4.8-11-12.3-11L34.6,25z
	 M83.9,46.8c-0.2,2.7-0.8,5.3-1.9,7.8l-7.1,18.6c-1,2.6-2,5.7-2,7.3c0,1.8,0.6,3.1,2.5,3.1c5.1,0,20.8-21.4,27.4-40.7h7L97.4,82.5
	l1,0.9c3.1-3.1,5.9-6.4,8.2-10.1h3.7C106,80,100.1,86.6,97.2,88.7h-5.5c-1.9-1.3-3-3.8,0.4-12.7l4.4-11.5h-1.3
	C90.7,73,80.1,89.6,70.3,89.6c-0.8,0.1-1.7-0.1-2.5-0.3C67,89,66.3,88.5,65.7,88c-0.6-0.6-1.1-1.3-1.4-2.1c-0.3-0.8-0.5-1.6-0.4-2.5
	c0-2.8,1.4-6.9,3.4-12.1l8.6-22.2l-1-0.9c-1.6,0.9-6,5.6-9.3,9.2h-3.7c3.8-4.5,10.5-11.7,14.5-14.5h5.5c0.7,0.4,1.2,1,1.6,1.6
	C83.8,45.2,84,46,83.9,46.8z M123.1,83.4c1.6-0.9,6-5.6,9.3-9.2h3.7c-3.8,4.5-10.5,11.7-14.5,14.5h-5.5c-0.7-0.4-1.2-1-1.6-1.6
	c-0.4-0.7-0.5-1.4-0.5-2.2c0-1.9,0.7-4.3,1.7-7.9l15.1-53l-1-0.9h-8.2l0.9-3.1h18.2L122,82.5L123.1,83.4z M148.3,83.4
	c1.6-0.9,5.9-5.6,9.3-9.2h3.7c-3.8,4.5-10.5,11.8-14.5,14.5h-5.5c-0.7-0.4-1.2-1-1.6-1.6c-0.4-0.7-0.6-1.4-0.5-2.2
	c0-1.9,0.7-4.3,1.7-7.9l15.1-53l-1-0.9h-8.2l0.9-3.1H166l-18.7,62.5L148.3,83.4z M172.2,20.6h35v28c0,7.6,3.2,15.7,17.8,15.7h0.2
	c14.3,0,17.8-8.1,17.8-15.7v-28h35v31.6c0,13.6-5.2,37.7-50.7,37.7h-4.6c-45.4,0-50.5-24.1-50.5-37.7L172.2,20.6z M318.8,70.1v18.6
	h-34.8V20.6h67.7c26.4,0,33.4,10.4,33.4,23.3v2.4c0,12.8-7.4,23.9-33.4,23.9H318.8z M318.8,49.5h24.4c4.8,0,6.1-2.1,6.1-4.4v-0.2
	c0-2.5-1.4-4.4-6.1-4.4h-24.4V49.5z"
    />
  </svg>
);

export default PullUp;
