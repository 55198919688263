import React from 'react';

const CtaArrow = props => (
  <svg {...props} viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2138 12.3222L11.8517 7.04999L4.87119 14.0305L3.53617 12.6955L10.5167 5.71497L5.24454 5.35293L7.28101 3.31647L13.8656 3.70113L14.2503 10.2857L12.2138 12.3222Z"
      fill="currentColor"
    />
  </svg>
);

export default CtaArrow;
