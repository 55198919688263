import React, { useEffect } from 'react';
import Link from 'gatsby-link';
import { MAIN_CONTENT_ID } from 'common/a11y';
import { scrollToDestination } from 'common/scrollToElement';
import SoundManager from '../../common/sound-manager.js';
import Styles from './pullup-link.module.css';
import Svg from './pullup-svg.js';

const PullupLink = props => {
  useEffect(() => {
    SoundManager.addSound('scroll-to-top', 'scroll'); // audio filename & id
  }, []);

  return (
    <Link
      to={`#${MAIN_CONTENT_ID}`}
      className={`${Styles.pullupLink} ${props.className || ''}`}
      aria-label="back to top"
      onClick={event => {
        event.preventDefault();
        document.getElementById(MAIN_CONTENT_ID).focus({ preventScroll: true });
        SoundManager.playUnique('scroll');
        scrollToDestination(0);
      }}
    >
      <Svg />
    </Link>
  );
};

export default PullupLink;
