import React, { useContext, useEffect } from 'react';
import ThemeContext, { THEMES } from 'common/context.theme';

import AppContext from 'common/context.app';
import AspectRatioBox from 'components/aspect-ratio-box';
import BackArrow from 'components/svgs/backarrow';
import Cta from 'components/cta';
import FxPlayer from 'components/fx-player';
import Link from 'gatsby-link';
import PullUp from 'components/pullup-link';
import SEO from 'components/seo';
import SpotifyPlayer from 'components/spotify-player';
import Styles from './soundsystems.module.css';
import VideoPlayer from 'components/video-player';
import { sendTrackingEvent } from '../common/sendTrackingEvent';
import { useIsDesktop } from 'common/hooks/useIsDesktop';

const SoundSystemTemplate = props => {
  const { setCurrentPage } = useContext(AppContext);
  const { setTheme } = useContext(ThemeContext);
  const isDesktop = useIsDesktop();

  const { pageContext } = props;
  const pageData = pageContext.data.node;

  useEffect(() => {
    setTheme(THEMES.dark);
    setCurrentPage('/sound-systems/');
  }, [setTheme, setCurrentPage]);

  const {
    name,
    description,
    spotify_id,
    video_url,
    cover,
    pre_images,
    post_images,
  } = pageData;

  const image = cover;

  return (
    <div className={`nhc-container-right nhc-container-left`}>
      <SEO title={name} description={description} />
      <Link to="/sound-systems/" className={Styles.backarrow}>
        <BackArrow width="13.66" height="9.86" />
        <span>Back to sound systems</span>
      </Link>

      <div className={Styles.layout}>
        <div>
          <div className={Styles.leftColumn}>
            {isDesktop && (
              <div className={Styles.coverContent}>
                <span id="top" />
                <picture>
                  <img
                    src={image}
                    aria-hidden="true"
                    loading="lazy"
                    alt={name}
                  />
                </picture>
                {spotify_id && (
                  <Cta href={spotify_id} className={Styles.cta}>
                    Playlist on Spotify
                  </Cta>
                )}
              </div>
            )}
            {isDesktop && <FxPlayer />}
          </div>
        </div>
        <div className={Styles.rightColumn}>
          {!isDesktop && (
            <div className={Styles.coverContent}>
              <span id="top" />
              <picture>
                <img src={image} aria-hidden="true" loading="lazy" alt={name} />
              </picture>
              {spotify_id && (
                <Cta href={spotify_id} className={Styles.cta}>
                  Playlist on Spotify
                </Cta>
              )}
            </div>
          )}
          <div className={Styles.bioContent}>
            <h1 className={Styles.name}>{name}</h1>
            <div className={Styles.bio}>{description}</div>
            {spotify_id && (
              <Cta
                href={spotify_id}
                className={Styles.ctaMobileOnly}
                onClick={() =>
                  sendTrackingEvent('spotify platform', 'click', name)
                }
              >
                Playlist on Spotify
              </Cta>
            )}
          </div>
          <div className={Styles.media}>
            {video_url && (
              <AspectRatioBox aspectRatio={16 / 9}>
                {video_url && <VideoPlayer url={video_url} title={name} />}
              </AspectRatioBox>
            )}
            <div className={Styles.pre}>
              {pre_images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt=""
                  aria-hidden="true"
                  className={Styles.image}
                />
              ))}
            </div>
            {spotify_id && (
              <SpotifyPlayer
                title={`Spotify playlist: ${name}`}
                uri={spotify_id}
                className={Styles.spotify}
              />
            )}

            <div className={Styles.post}>
              {post_images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt=""
                  aria-hidden="true"
                  className={Styles.image}
                />
              ))}
            </div>
          </div>
          {!isDesktop && <FxPlayer />}
          <PullUp className={Styles.pullUp} />
        </div>
      </div>
    </div>
  );
};

export default SoundSystemTemplate;
