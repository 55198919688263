import React, { useEffect, useState } from 'react';

import FxPlayerButton from './fx-button.js';
import Panel from 'images/fx/panel.svg';
import SfxTitle from 'images/fx/sfx.svg';
import SoundManager from '../../common/sound-manager.js';
import Styles from './fx-player.module.css';

const MAX_SFX = 9;

const FxPlayer = () => {
  const [activeMode, setActiveMode] = useState(false);
  const buttons = [];

  useEffect(() => {
    for (let i = 1; i <= MAX_SFX; i++) {
      SoundManager.addSound(i, `sfx${i}`);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  });

  function playEffect(name) {
    if (activeMode) SoundManager.play(name);
    else SoundManager.playUnique(name);
  }

  function toggleMode() {
    setActiveMode(!activeMode);
  }

  function handleKeyDown(e) {
    const allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

    if (allowedKeys.includes(e.key)) {
      playEffect(`sfx${e.key}`);

      const btn = document.getElementsByClassName(`sfxplayerbtn${e.key}`)[0];
      btn.focus();

      setTimeout(() => {
        btn.blur();
      }, 100);
    }
  }

  let key = 0;

  for (let i = 1; i <= MAX_SFX; i++) {
    let theme = 'fxButtonRed';
    if (key > 2) theme = 'fxButtonLavender';
    if (key > 5) theme = 'fxButtonCyan';

    buttons.push(
      <FxPlayerButton
        key={key}
        index={i}
        theme={theme}
        className={`sfxplayerbtn${i}`}
        onClick={() => {
          playEffect(`sfx${i}`);
        }}
      />
    );
    key++;
  }

  const activeModeClass = activeMode
    ? `${Styles.recGain} ${Styles.recGainActive}`
    : Styles.recGain;

  return (
    <div className={Styles.fxPlayer}>
      <div
        className={activeModeClass}
        role="presentation"
        onClick={() => {
          toggleMode();
        }}
      />
      <img className={Styles.fxPlayerPanel} src={Panel} alt="" />
      <img className={Styles.fxPlayerTitle} src={SfxTitle} alt="" />
      <div className={Styles.fxPlayerButtonsContainer}>{buttons}</div>
    </div>
  );
};

export default FxPlayer;
