import React from 'react';

import PropTypes from 'prop-types';
import Styles from './video-player.module.css';

const VIMEO_URL = 'https://vimeo.com/';
const EMBED_URL = 'https://player.vimeo.com/video/';

const VideoPlayer = props => {
  const URL = props.url.replace(VIMEO_URL, EMBED_URL);

  return (
    <div className={Styles.videoPlayer}>
      <iframe
        title={props.title}
        src={`${URL}?color=${props.theme.substr(
          1
        )}&title=0&byline=0&portrait=0`}
        frameBorder="0"
        allow="autoplay; fullscreen"
        aria-label={props.title}
      />
    </div>
  );
};

VideoPlayer.defaultProps = {
  theme: '#191414',
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

export default VideoPlayer;
